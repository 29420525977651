.payment_pages{
    .page_title{
        span{
            @include font(14px);
            font-weight: 400;
        }
    }
    .items_detail{
        margin-top: 50px;
        .titleSmall{
            font-weight: 700;
        }
        .crm_table{
            margin-bottom: 60px;
            .table{
                .show_added_list{
                    padding: 0px 20px;
                }
            }
        }
        .add_new_quote{
            .quote_card{
                padding: 40px 30px 1px 30px;
                margin-bottom: 60px;
                .QuoteFrm{
                    .form-group{
                        padding-bottom: 10px;
                        margin-bottom: 20px;
                        &:last-child{
                            margin-bottom: 10px;
                        }
                    }
                } 
            }
        }  
    }
}
.payment_option{
    .show_total{
        @include flex();
        border-bottom: 1px solid $border;
        align-items: center;
        margin-bottom: 30px;
        padding-bottom: 20px;
        h3{
            @include font(24px);
            font-weight: 600;
            margin-bottom: 0;
            color: #4B4B4B;
        }
    }
    .custome_radio{
        margin-bottom: 30px;
        border-bottom: 1px solid $border;
        padding-bottom: 30px;
        .opti_checkbox{
            @include font(16px !important);
            @include line(24px);
            font-weight: 600;
            padding-left: 50px;
            a{
                color: $primary;
            }
        }

    }
    .pay_paypal{
        text-align: center;
        .paypal_btn{
            height: 45px;
            background: #009cde;
            border: none;
            display: block;
            border-radius: 50px;
            @include line(40px);
            text-align: center;
            margin-bottom: 15px;
            width: 100%;
            &.credit{
                background: #003087;
            }
        }
    }
}

.payment_container{
    flex-direction: column;
}
.opti_footer_wrapper{
    background: $white;
    padding: 60px 45px;
    .small_heading{
        @include font(14px);
        font-weight: 700;
        color: $primary;
        margin-bottom: 50px;
    }
    .status_divider{
        border-bottom: 1px solid $border;
        padding-bottom: 40px;
        margin-bottom: 40px;
        h4{
            @include font(20px);
            font-weight: 700;
            color: $base-text;
            margin-bottom: 20px; 
        }
        p{
            margin-bottom: 0;
        }
        .btn-main{
            min-width: 250px;
            height: 60px;
            line-height: 60px;
        }
        &:last-child{
            border-bottom: 0;
        }
    }
    .return_cost{
        @include font(14px);
        font-weight: 700;
        color: $base-text;
        margin-bottom: 20px;
        span{
            color: $primary;
            margin-left: 10px;
        }
    }
    .section_heading{
        border-bottom: 1px solid $border;
        padding-bottom: 20px;
        margin-bottom: 40px;
        h2{
            @include font(28px);
            font-weight: 700;
            color: $heading;
            margin-bottom: 0px;
        }
        p{
            margin-bottom: 0;
        }
        .couponCode{
            background: rgba(202, 60, 60, 0.10);
            border-radius: 4px;
            text-align: center;
            padding: 20px 15px;
            @include font(16px);
            font-weight: 600;
            span{
                font-weight: 700;
                color: $primary;
            }
        }
    }
    .product_box{
        .prduct_img{
            min-height: 200px;
            text-align: center;
        }
        .btn-main{
            min-width: inherit;
            height: 60px;
            line-height: 60px;
        }
    }
}