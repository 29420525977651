//custome theme variables

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');

$montserrat: 'Montserrat', sans-serif;

// Color system
$primary:       #CA3C3C;
$secondary:		#311313;
$link:			$primary;
$text-dark:     #282828;
$red:			$primary;
$dark-red:		#910000;
$base-text:		#3E3E3E;
$offtext:		#9B9B9B; 
$dark: 			#1A0909;
$offwhite:		#8D8484;
$white:		    #fff;
$black:			#000000;
$offgrey:		#A7A7A7;
$inputText:		#4D4F5C;
$body-bg:		#f9f9f9;
$heading:		#323C19;
$border:		#DEDEDE;
$green:			#61B95A;
$placeholder:	#BEBEBE;

//width
$maxfull:       100%;

.text-red{
	color: $red !important;
}
.text-green{
	color: $green !important;
}
.text-offtext{
	color: $offtext;
	font-weight: 400;
}