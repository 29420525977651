.invoice_single{
    .section_title{
        @include flex();
        align-items: center;
        margin-bottom: 20px;
        h2{
            color: $heading;
            @include font(28px);
            font-weight: 600;
            margin-bottom: 0;
        }
        p{
            margin-bottom: 0;
            margin-left: auto;
            font-weight: 700;
            @include font(14px);
        }
    }
    .invoice_header{
        padding-bottom: 30px;
        margin-bottom: 40px;
        border-bottom: 1px solid $border;
        p{
            margin-bottom: 0;
        }
    }
    .invoice_update_form{
        border-bottom: 1px solid $border;
        margin-bottom: 40px;
        padding-bottom: 30px;
        label{
            @include font(14px);
            font-weight: 700;
            color: $base-text;
        }
        .form-control{
            color: $red;
        }
        p{
            @include font(12px);
            color: #6D6D6D;
            margin-bottom: 0;
        }

    }
    .customer_info{
        margin-bottom: 50px;
        h4{
            color: $heading;
            @include font(20px);
            font-weight: 600;
            margin-bottom: 40px;
        }
        .ci_area{
            margin-bottom: 30px;
            padding-left: 20px;
            h5{
                color: $heading;
                @include font(14px);
                font-weight: 700;
                margin-bottom: 10px;
            }
            p{
                color: $base-text;
                @include font(14px);
                margin-bottom: 2px;
            }
        }
    }
    .add_new_quote{
        .quote_card{
            .card_title{
                font-weight: 600;
                span{
                    display: inline-block;
                    font-size: 12px;
                    color: #6D6D6D;
                    margin-left: 20px;
                    font-weight: 400;
                }
            }
            .QuoteFrm{
                .form-group{
                    margin-bottom: 0;
                    padding: 30px 0px;
                    p{
                       font-size: 14px;
                    }
                }
            }
        }
    }  
}
.quote_step_btn{
    @include flex();
    margin-top: -30px;
    margin-bottom: 20px;
    padding: 0px 60px;
    .quote_step_prev{
        display: inline-block;
        height: 40px;
        min-width: 160px;
        @include font (14px);
        @include line (38px);
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        @include radius(50px);
        background: $offgrey;
        color: $black;
        @include transition(0.3s);
        cursor: pointer;
        font-weight: 500;
        padding: 0px 20px;
        opacity: 0.9;
        &:focus,
        &.focus {
            outline: 0;
        }
        &:hover, &:focus{
            opacity: 1;
        }
    }
    .quote_step_next{
        display: inline-block;
        height: 40px;
        min-width: 160px;
        @include font (14px);
        @include line (38px);
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        @include radius(50px);
        background: $primary;
        color: $white;
        @include transition(0.3s);
        cursor: pointer;
        font-weight: 500;
        padding: 0px 20px;
        // opacity: 0.9;
        margin-left: auto;
        &:focus,
        &.focus {
            outline: 0;
        }
        &:hover, &:focus{
           background: $secondary;
        }
    }
}

.equipment_slider {
    height: 650px;
    .awssld__content{
        background-color: #fff;
    }
}