.log_right_sidebar{
	h2{
		font-size: 18px;
		border-bottom: 1px solid #eee;
		margin-bottom: 30px;
	}
	.error{
		color: #CA3C3C;
		font-size: 12px;
	}
}
.clients_page{
	.crm_table{
		.table{
			.head_row{
				padding: 0px 20px;
				.col{
					text-align: left;
					.toggle_dot{
						cursor: pointer;
					}
				}
			}
			.show_added_list{
				padding: 0px 20px;
				height: 100px;
				.row{
					.col{
						text-align: left;
						h4{
							font-size: 16px;
						    margin-bottom: 4px;
						    font-weight: 700;
						    color: #3E3E3E;
						}
						.client_email{
							margin-bottom: 1px;
							font-size: 14px;
							color: #3E3E3E;
							font-weight: 600;
						}
						.address{
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	
}
.add_client{
	.form-group{
		position: relative;
		label.form-control-placeholder{
			position: absolute;
			top: 0;
			left: 0;
			padding: 14px 0 0 20px;
			transition: all 0.2s;
			font-size: 14px;
			color: #999;
			pointer-events: none;
			margin-bottom: 0;
			font-weight: 500;
		}
		&.active{
			label{
				font-size: 12px;
				transform: translate3d(0, -60%, 0);
				opacity: 1;
				padding: 3px 8px 0;				
				color: #1A0909;
				background: #fff;
				left: 15px;
			}
		}
		.form-control{
			height: 50px;
			line-height: 50px;
			color: #1A0909;
			padding-left: 20px;
		}
		select.form-control{
			color: #CA3C3C;
			height: 50px !important;
			line-height: 50px;
		}
		textarea.form-control{
			height: auto;
		}
	}
}