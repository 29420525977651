.breadcrumb{
    background: transparent;
    border-bottom: 1px solid $border;
    padding-left: 0;
    border-radius: 0;
    margin-bottom: 40px;
    li{
        color: $heading;
        a{
            color: $primary;
        }
    }
}