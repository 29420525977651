.accordion{
    // padding-right: 35px;
    .card{
        margin-bottom: 20px;
        border: none;
        overflow: inherit;
        @include box-shadow(0px 3px 9px rgba(190, 190, 190, 0.42));
        border-radius: 4px !important;
        border-left: 5px solid $dark;
        padding: 0px 20px;
        position: relative;
        .card-header{
            border-radius: 0px;
            background: $white;
            border-bottom: 1px solid $border;
            padding-left: 0;
            padding-right: 0;
            .text-red{
                @include font(14px);
            }
            h2{
                button{
                    color: $base-text;
                    @include font(14px);
                    img{
                        margin-left: 20px;
                    }
                    &:hover, &:focus{
                        text-decoration: none;
                    }					
                }					
            }
            &.card_open{
                img.minus{
                    display: inline-block;
                }
                img.plus{
                    display: none;
                }
            }
            &.card_close{
                img.minus{
                    display: none;
                }
                img.plus{
                    display: inline-block;
                }
            }
            .small{
                font-size: 50%;
                line-height: normal;
                @include flex();
            }
        }
        .card-body{
            padding: 35px 15px;
            .form_button{
                border-top: 1px solid $border;
                margin-top: 50px;
                padding-top: 30px;
                @include flex();
            }
        }
        .log_action{
            position: absolute;
            top: 15px;
            right: -20px;
        }
    }
}