
ol.progtrckr {
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    margin-top: 50px;
    // border-bottom: 1px solid #dedede;
    padding-bottom: 20px;
  }
  ol.progtrckr li {
    border: 1px solid transparent !important;
    min-width: 142px;
    border-radius: 4px;
    text-align: center;
    line-height: 33px;
    cursor: pointer;
    margin-right: 20px;
    &:before{
      display: none !important;
    }
  }
  ol.progtrckr li span {
    padding: 0;
  }
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }
  
  ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
  }
  ol.progtrckr li.progtrckr-doing {
    border: 1px solid #CA3C3C !important;
  }
  ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid #33C3F0;
  }
  ol.progtrckr li:after {
    content: "\00a0\00a0";
  }
  ol.progtrckr li:before {
    position: relative;
    bottom: -4rem;
    float: left;
    left: 50%;
  }
  ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.4em;
  }
  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #0FA0CE;
  }
  
  ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #33C3F0;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #0FA0CE;
  }
  
  ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #33C3F0;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  ol.progtrckr li.progtrckr-done:hover:before {
    color: #0FA0CE;
  }
