.order_page{
    .section_title{
        @include flex();
        align-items: center;
        margin-bottom: 30px;
        h2{
            color: $heading;
            @include font(28px);
            font-weight: 600;
            margin-bottom: 0;
        }
        p{
            margin-bottom: 0;
            margin-left: auto;
            font-weight: 400;
            @include font(14px);
            color: $green;
            .status_paid{
                display: inline-block;
                border-radius: 50px;
                border: 1px solid $green;
                color: $green;
                min-width: 110px;
                text-align: center;
                padding: 2px 0px;
                margin-left: 15px;
            }
        }
    }
    .accordion{
        .card{
            border-left: none;
            .card-header{
                .card_close{
                    .plus{
                        display: block;
                    }
                    .minus{
                        display: none;
                    }
                }
                .card_open{
                    .plus{
                        display: none;
                    }
                    .minus{
                        display: block;
                    }
                }
                .btn-link{
                    @include flex();
                    align-items: center;
                    font-weight: 700;
                }
            }
        }
    }
    .crm_table{
        .table{
            .show_added_list{
                padding: 0px 15px;
                height: 80px;
                margin-bottom: 15px;
            }
        }
    }
    .order_shipping_info{
        margin-top: 78px;
        padding-left: 30px;
        .shipping_info_card{
            @include box-shadow(0px 3px 9px rgba(190, 190, 190, 0.42));
            background: $white;
            border-radius: 4px;
            padding: 30px 30px;
            margin-bottom: 30px;
        }
        .no_shipping{
            @include font(18px);
            @include line(21px);
            margin-bottom: 0;
            padding: 0px 50px;
            text-align: center;
        }
        h5{
            @include font(14px);
            font-weight: 700;
            margin-bottom: 15px;
        }
        .unit_of_ship{
            @include flex();
            align-items: center;
            border-bottom: 1px solid $border;
            margin-bottom: 10px;
            padding-bottom: 10px;
            label.my-auto{
                padding-left: 15px;
                @include font(18px);
            }
        }
        .shipping_carrier_order{
            margin-top: 40px;
            h5{
                margin-top: 20px;
            }
        }
    }
    .sent_shipping_info{
        margin-top: 78px;
        padding-left: 30px;
        .accordion{
            .card-header{
                border-bottom: none;
                padding: 20px 0px;
                margin-bottom: 0;
                p.my-auto{
                    @include font(18px);
                    font-weight: 700;
                }
            }
        }
    }
}