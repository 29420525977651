/*==========| Header section scss |==========*/
.header{
	background: $white;
	height: 70px;
	@include box-shadow(0px 2px 6px rgba(0, 0, 0, 0.04));
	@include flex();
	align-items: center;
	position: relative;
	.search_top{
		position: relative;
		img{
			position: absolute;
			top: 50%;
			margin-top: -8px;
			left: 0;
		}
		.form-control{
			background: transparent;
			border: none;
			color: $inputText;
			padding-left: 40px;
			&:focus{
				background: $white;
			}

		}
	}
	.top_rols{
		padding-right: 20px;
		border-right: 1px solid #EBEBF2;
		a{
			display: inline-block;
			padding: 0px 10px;
			&.active{
				svg{
					path{
						fill: $red;
					}
				}
			}
		}
	}
	.user_profile_top{
		padding-left: 20px;
		position: relative;
		.dropdown{
			padding-right: 50px;
			.dropdown-toggle{
				@include font(14px);
				color: $inputText;
				.pro_img{
					display: inline-block;
				    width: 38px;
				    height: 38px;
				    background: #333;
				    border-radius: 50%;
				    position: absolute;
				    right: 0;
				    top: 50%;
				    margin-top: -19px;
				}
			}
		}
	}
}

.main_container{
	@include flex();
	flex-direction: row;
	min-height: calc(100vh - 70px);
	.opti_wrapper{
		width: 100%;
		padding: 45px;
		@include screen(laptop){
			padding: 15px;
		} 
	}
}


.log_page_header{
	@include flex();
	align-items: center;
	.log_page_title{
		flex: 0 0 30%;
	}
	.btn-and-search{
		flex: 0 0 70%;
		flex-direction: column;
		.opti_search{
			margin-left: auto;
			width: 450px;
		}
		.dropdown{
			margin-left: auto;
			width: 400px;
			@include flex();
			align-items: center;
			margin-top: 10px;
			label{
				color: #BCBCBC;
				@include font(14px);
				font-weight: 500;
				margin-bottom: 0;
			}
			.form-control{
				width: 70%;
				margin-left: auto;
				background: #4B4B4B;
				color: $white;
			}
		}
	}
}