//text alignment
@mixin text-left {
	text-align: left;
}
@mixin text-right {
  	text-align: right;
}
@mixin text-center {
  	text-align: center;
}

//display property
@mixin display($display-type) {
  	display:$display-type;
}




//ul clear
@mixin ul-clear{
	padding:0;
	margin:0;
}

//line height
@mixin line ($lineheight-value){
	line-height:$lineheight-value;
}

//font size
@mixin font ($font-value){
	font-size:$font-value;
}

//font family
@mixin family($fontfamily){
	font-family: $fontfamily;
}


//positions
@mixin position($position-value){
	position:$position-value;
}

//border
@mixin border-none{
	border:0;
}
@mixin border( $border-properties){
	border: $border-properties;
}
@mixin borderleft ($border-properties){
	border-left: $border-properties;	
}
@mixin border-right ($border-properties){
	border-right: $border-properties;	
}
@mixin border-top ($border-properties){
	border-top: $border-properties;	
}
@mixin border-bottom ($border-properties){
	border-bottom: $border-properties;	
}

//border radius
@mixin radius($radius){
	-webkit-border-radius:($radius);
	   -moz-border-radius:($radius);
			border-radius:($radius);
}
@mixin border-top-left-radius($radius){
	-webkit-border-top-left-radius:($radius);
	   -moz-border-top-left-radius:($radius);
			border-top-left-radius:($radius);	
}
@mixin border-top-right-radius($radius){
	-webkit-border-top-right-radius:($radius);
	   -moz-border-top-right-radius:($radius);
			border-top-right-radius:($radius);	
}
@mixin border-bottom-left-radius($radius){
	-webkit-border-bottom-left-radius:($radius);
	   -moz-border-bottom-left-radius:($radius);
			border-bottom-left-radius:($radius);	
}
@mixin border-bottom-right-radius($radius){
	-webkit-border-bottom-right-radius:($radius);
	   -moz-border-bottom-right-radius:($radius);
			border-bottom-right-radius:($radius);	
}

//transitions
@mixin transition($transition-value){
	-webkit-transition:$transition-value;
	-webkit-transition:$transition-value;
			transition:$transition-value;
}

//animation duration
@mixin timeduration($timeduration){
	-webkit-animation-duration:$timeduration;
  			animation-duration:$timeduration;
}

//animation name
@mixin animationname($animname){
	-webkit-animation-name: $animname;
 		    animation-name: $animname;
}

//opacity
@mixin opacity($opacity-value){
	opacity:$opacity-value;
}

//transform
@mixin transform($transform-value){
	-webkit-transform: $transform-value;
		    transform: $transform-value;
}

//box shadow
@mixin box-shadow( $bsval){
	-webkit-box-shadow: $bsval;
			box-shadow: $bsval;
}

//padding
@mixin padding($padding-value){
	padding:$padding-value;
}
@mixin padding-top($paddingtop-value){
	padding-top:$paddingtop-value;
}
@mixin padding-right($paddingright-value){
	padding-right:$paddingright-value;
}
@mixin padding-bottom($paddingbottom-value){
	padding-bottom:$paddingbottom-value;
}
@mixin padding-left($paddingleft-value){
	padding-left:$paddingleft-value;
}

//margin
@mixin margin($margin-value){
	margin:$margin-value;
}
@mixin margin-top($margintop-value){
	margin-top:$margintop-value;
}
@mixin margin-right($marginright-value){
	margin-right:$marginright-value;
}
@mixin margin-bottom($marginbottom-value){
	margin-bottom:$marginbottom-value;
}
@mixin margin-left($marginleft-value){
	margin-left:$marginleft-value;
}


//width
@mixin maxfull{
	max-width:$maxfull;
}
//@mixin calc($calcwidth){
//	width:-webkit-($calcwidth);
//	width:$calcwidth;
//}

//index
@mixin zindex($index-value){
	z-index:$index-value;
}

//background properties
@mixin back-size($back-size-property){
	-webkit-background-size:$back-size-property;
	   -moz-background-size:$back-size-property;
			background-size:$back-size-property;
}
@mixin back-posi($back-position){
	-webkit-background-position:$back-position;
	   -moz-background-position:$back-position;
			background-position:$back-position;
}
@mixin back-repeat($repeat-value){
	-webkit-background-repeat:$repeat-value;
	   -moz-background-repeat:$repeat-value;
			background-repeat:$repeat-value;
}
@mixin back-attach($attachment-value){
	-webkit-background-attachment:$attachment-value;
	   -moz-background-attachment:$attachment-value;
			background-attachment:$attachment-value;
}


//gradient
 @mixin linear-grad ($gradupcolor, $graddowncolor){
 	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from($gradupcolor), to($graddowncolor));
	background: -webkit-linear-gradient(top, $graddowncolor, $gradupcolor);
	background: -moz-linear-gradient(top, $graddowncolor, $gradupcolor);
	background: -ms-linear-gradient(top, $graddowncolor, $gradupcolor);
	background: -o-linear-gradient(top, $graddowncolor, $gradupcolor);
 }

//text transform
@mixin text($text-transform-value){
	text-transform:$text-transform-value;
}






//flex properties
@mixin flex {
  	display:-webkit-flex;
  	display:-moz-flex;
  	display:flex;
}
@mixin flexwrap($wrapvalue) {
  -webkit-flex-wrap:$wrapvalue;
  		flex-wrap:$wrapvalue;
}
@mixin inline-flex {
  display:-webkit-inline-flex;
  display:-moz-inline-flex;
  display:inline-flex;
}
@mixin full-width {
  width:100%;
}



//Responsive Resolution
@mixin screen($size) {
  $fhd: "(max-width: 1920px)";
  $tabletXL: "(min-width: 768px) and (max-width: 991px)"; 
  $laptop: "(min-width: 1201px) and (max-width: 1440px)";
  $desktops: "(min-width: 992px) and (max-width: 1200px)";
  $tablet: "(min-width: 768px) and (max-width: 991.98px)";
  $upmobile: "(min-width: 768px)";
  $mobile: "(max-width: 767px)";

  @if $size == desktops {
    @media only screen and #{$desktops} {
      @content;
    }
  }
  @else if $size == fhd {
    @media only screen and #{$fhd} {
      @content;
    }
  }
  @else if $size == laptop {
    @media only screen and #{$laptop} {
      @content;
    }
  }
  @else if $size == tabletXL {
    @media only screen and #{$tabletXL} {
      @content;
    }
  }
  @else if $size == tablet {
    @media only screen and #{$tablet} {
      @content;
    }
  }
  @else if $size == mobile {
    @media only screen and #{$mobile} {
      @content;
    }
  }
  @else if $size == upmobile {
    @media only screen and #{$upmobile} {
      @content;
    }
  } 
  @else {
    @media only screen and #{$size} {
      @content;
    }
  }

}


