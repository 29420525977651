
body{
	 font-family: 'Montserrat', sans-serif;
   @include font(14px);
   font-weight: 400;
   color: $base-text;
   background: $body-bg;

}

@media only screen and (min-width: 1400px){
  .container{
    max-width: 1320px;
  }
}

@media only screen and (min-width: 840px) and (max-width: 960px){
  .container{
    max-width: 840px;
  }
}

a:hover,
a:focus{
	outline:none;
	text-decoration:none;
}
button:focus{
    outline:none;
}

p{
	@include font(16px);
	@include line (26px);
	color:$black;
}
img{
	max-width:100%;
}
ul,ol{
  list-style: none;
}
h1, .theme_title{
  @include font(45px);
  color: $primary;
  @include line (54px);  
  margin-bottom: 40px;
  @include screen(mobile){
    @include font(36px);
    @include line (44px);
    margin-bottom:30px;
  }
}
h2{
  @include font(30px);
  color: $primary;
  @include line (45px);  
}
h3{
  @include font(24px);
  color: $black;
  @include line (45px);  
  font-weight: 700;
    @include screen(mobile){
      @include font(20px);
      @include line (30px);
    }
}

hr{
  border-top: 2px solid #ccc;
  @include margin(60px 0px);
  @include screen(mobile){
    @include margin(30px 0px);
  }
}

//  Theme Button
.btn-main {
  display: inline-block;
  height: 40px;
  min-width: 160px;
  @include font (14px);
  @include line (38px);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  @include radius(50px);
  background: $primary;
  color: $white;
  @include transition(0.3s);
  cursor: pointer;
  font-weight: 500;
  padding: 0px 20px;
  // Share hover and focus styles
  &:focus,
  &.focus {
    outline: 0;
  }
  &:hover{
    color: $white;
    background: $secondary;
    border: 1px solid $secondary;
  }
  @include screen(mobile){
    display: block;
    width: 100%;
  }
  &.secondary{
    border: 1px solid $white;
    background: $dark;
    min-width: inherit;
    padding: 0px 30px;
    text-align: center;
    &:hover{
      color: $white;
      background: $primary;
      border: 1px solid transparent;
    }
  }
  &.btn-sm{
    @include font(12px);
    height: 30px;
    line-height: 28px;
    padding: 0px 35px;
    min-width: inherit;
  }
  &.link-btn{
    background: transparent;
    color: $offgrey;
    height: 30px;
    line-height: 28px;
    padding: 0px 20px;
    min-width: inherit;
    &:hover, &:focus{
        background: transparent;
        color: $primary;
        border: 1px solid transparent;
    }
  }
  &.no-radius{
    border-radius: 0;
  }
  &:disabled, &.disabled{
    pointer-events: none;
    background: #6d6d6d;
    opacity: 0.2;
    color: $white;
  }
  &.btn-green{
    background: $green;
    &:hover{
      background: $secondary;
    }
  }
  &.pending{
    background: #5A89B9;
    &:hover{
      background: $secondary;
    }
  }
  &.btn-black{
    background: $base-text;
  }
  &.btn-line{
    background: transparent;
    border: 1px solid $red;
    color: $red;
  }
}


//  Form control input field
.form-control{
  background: #fff;
  border: 1px solid $border;
  height: 40px;
  @include line(40px);
  @include font(14px);
  border-radius: 4px;
  text-align: left;
  color: $base-text;
  font-weight: 500;
  &:focus{
    outline: none;
    @include box-shadow(none);
     border: 1px solid $dark;
  }
  &[readonly]{
    background: $white;
  }
}
select.form-control{
  height: 40px !important;
  border: 1px solid $border;
  color: $primary;
  &:focus{
    border: 1px solid $dark;
  }
}
textarea[readonly]{
  line-height: 20px;
}

.main_heading{
  text-align: center;
  margin-bottom: 40px;
  h1{
    @include line(60px);
    @include font(36px);
    color: #0B1C35;
    font-weight: 700;
    &:after{
      content: '';
      display: block;
      width: 50px;
      height: 3px;
      background: #0B1C35;
      margin: 20px auto 0;
    }
    @include screen(mobile){
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 25px;
    }
  }
  p{
    @include font(18px);
    padding: 0px 40px;
    margin-bottom: 0;
    @include screen(desktops){
      padding: 0;
    }
    @include screen(mobile){
      font-size: 14px;
      padding: 0;
      line-height: 24px;
    }
  }
  &.light{
    h1{
      color: #fff;
      &:after{
        background: #fff;
      }
    }
    p{
      color: #fff;
    }
  }  
}

.subscribe-result{
  h2{
    font-weight: 700;
    color: $primary;
    font-size: 30px;
  }
  h4{
    font-size: 24px;
    color: #fff;
    font-weight: 700;
  }
   p{
    font-size: 10px !important;
    font-weight: 500 !important;
    color: $primary !important;
    padding-left: 15px;
    a{
      display: none;
    }
  }
}

#mc-embedded-subscribe-form-footer{
  text-align: center;
  .subscribe-result{  
     p{
      padding-left: 20%;
      text-align: left;    
    }
  }
}


// Common classes
h1.page_title{
  @include font(28px);
  font-weight: 600;
  color: $heading;
  margin-top: 0;
  margin-bottom: 10px;
  .btn-main{
    margin-left: 20px;
  }
}
.btn-and-search{
    @include flex();
    .btn-main{
      min-width: 240px;
      text-align: left;
      color: $white !important;
      display: flex;
      img{
        margin: auto 0 auto auto;
      }
    }
    .opti_search{
        width: 40%;
        border: 1px solid $border;
        border-radius: 4px;
        position: relative;
        height: 40px;
        .form-control{
            background: transparent;    
            height: 40px;  
            width: 80%;
            padding: 0px 0px 0px 50px;
            line-height: 40px; 
            border: none;        
        }
        .search_btn{
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -20px;
            height: 40px;
            width: 20%;
            background: transparent;
            border: none;
            border-left: 1px solid $border;
            cursor: pointer;
            color: $red;
        }
        img{
            position: absolute;
            top: 50%;
            margin-top: -8px;
            left: 20px;
        }
    }
}

.crm_table{
    margin-top: 50px;
    .table{
      border-spacing: 0px 25px;
      border-collapse: separate;
        td,th{
            border-top: 1px solid transparent;
            @include font(14px);
            font-weight: 500;
            text-align: center;
            vertical-align: middle;
            .text-red{
                color: $red;
            }
        }
        th{
            color: $offgrey;
        }
        tr{
            background: $white;
            @include box-shadow(0px 3px 9px rgba(190, 190, 190, 0.42));
            border-radius: 4px;
            &:first-child{
                background: transparent;
                @include box-shadow(none);
            }
        }
        .head_row{
            margin-bottom: 25px;
            .col{
                text-align: center;
                // padding-left: 20px;
            }
        }
        .show_added_list{
            background: $white;
            @include box-shadow(0px 3px 9px rgba(190, 190, 190, 0.42));
            border-radius: 4px;
            height: 80px;
            @include flex();
            justify-content: center;
            flex-direction: column;
            margin-bottom: 30px;
            .row{
                height: 100%;
                .col{
                    margin: auto 0;
                    // padding-left: 20px;
                    text-align: center;
                }
            }
        }        
    }
    .fas{
        @include font(17px);
    }
}
.log-badge{
    background: transparent;
    border: 1px solid $green;
    border-radius: 20px;
    color: $green;
    @include font(12px);
    font-weight: 400;
    padding: 6px 15px;
    display: inline-block;
    vertical-align: middle;
    &.decomission{
        border: 1px solid $base-text;
        color: $base-text;
    }
}

.form-group{
    label{
        @include font(14px);
        font-weight: 700;
        color: $base-text;
        margin-bottom: 10px;
    }
    .input_text{
        padding: 15px;
        min-height: 80px;
    }
}

.dropdown{
    .dropdown-toggle{
        @include font(14px);
        color: $red;
        &:after{
            display: none;
        }
    }
    .dropdown-menu{
        @include box-shadow(0px 3px 9px rgba(190, 190, 190, 0.42));
        border: none;
        a{
            @include font(12px);
            font-size: 12px;
            padding-top: 8px;
            padding-bottom: 8px;
            font-weight: 600;
            line-height: normal;
        }
    }
}


.custome_radio{
    line-height: 40px;
    .opti_radio{
        position: relative;
        display: inline-block !important;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-left: 40px;
        margin-right: 30px;
        vertical-align: middle;
        margin-bottom: 0;
        line-height: 20px;
        font-size: 14px !important;
        color: #6D6D6D !important;
        input{
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        .checkmark{
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background: transparent;
            border: 1px solid $dark;
            border-radius: 50%;
            &:after{
                content: "";
                position: absolute;
                display: none;
                top: 4px;
                left: 4px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $red;
            }
        }
        input:checked ~ .checkmark:after {
            display: block;
        }
        &:last-child{
            margin-right: 0;
        }
        &.opti_checkbox{
          .checkmark{
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            width: 30px;
            background: transparent;
            border: 1px solid #BCBCBC;
            border-radius: 0;
            &:after{
                content: "";
                position: absolute;
                display: none;
                top: 50%;
                left: 50%;
                margin-left: -7.5px;
                margin-top: -7.5px;
                width: 15px;
                height: 15px;
                border-radius: 0;
                background: $red;
            }
          }
        }
      }
    }

.upload_file {
    font-size: 14px;
    line-height: 40px;
    width: 50%;
    margin-left: 20px;
    height: 40px;
    border: 1px solid #E0E0E0;
    background: #F8F8F8;
    overflow: hidden;
    border-radius: 50px;
    position: relative;
    input {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        position: absolute;        
    }
    span{
        display: block;
        cursor: pointer;
        padding-left: 20px;
        position: relative;
        &:after{
            content: '';
            //background: url(../images/upload-file.svg) no-repeat center;
            width: 7px;
            height: 14px;
            position: absolute;
            right: 20px;
            top: 14px;
        }
    }
}




.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $placeholder;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
  color: $placeholder;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
  color: $placeholder;
}
.form-control:-moz-placeholder { /* Firefox 18- */
  color: $placeholder;
}

button {
  background: none;
  border: none;
}


// quote page SCSS
.titleSmall{
  color: $base-text;
  @include font(16px);
  padding-bottom: 15px;
  margin-bottom: 20px;
  &.border_under{
    border-bottom: 1px solid $border;
  }
}
.unit_quoted{
  margin-top: 15px;
  .table{
    .show_added_list{
      .product_series{
        text-align: left !important;
        h3{
          @include font(10px);
          margin-bottom: 0;
          line-height: normal;
          color: #4B4B4B;
          font-weight: 600;
        }
        p{
          @include font(14px);
          margin-bottom: 0;
          line-height: normal;
        }
      }
    }
  }
}

.add_new_quote{
  .section_title{
    @include flex();
    flex-direction: row;
    align-content: center;
    margin-bottom: 20px;
    h2{
      color: $heading;
      @include font(28px);
    }
    .btn-main{
      margin-left: auto;
    }
  }
  .quote_card{
    background: $white;
    @include box-shadow(0px 3px 9px rgba(190, 190, 190, 0.42));
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 30px 30px 40px 30px;
    .card_title{
      border-bottom: 1px solid $border;
      color: $base-text;
      @include font(16px);
      padding-bottom: 10px;
      margin-bottom: 25px;;
    }
    .client_info_filter{
      .form-group{
        margin-bottom: 0;
        select{
          @include radius(100px);
        }
      }
    }
    .QuoteFrm{
      .form-group{
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 20px;
        .part_relative{
          position: relative;
          img{
            position: absolute;
            top: 50%;
            margin-top: -7px;
            left: 12px;
          }
          .form-control{
            border: none;
          }
        }
        .shipping_cost{
          position: relative;
          img{
            position: absolute;
            top: 50%;
            margin-top: -7px;
            left: 12px;
          }
          .form-control{
            padding-left: 30px;
          }
        }
        .part_remove{
          color: rgba(190, 190, 190, 0.5);
          &:hover, &:focus{
            color: $primary;
          }
        }
      }      
    }
    .review_form{
      .react-datepicker-wrapper{
        display: block;
      }
    }
  }
  .show_added_list{
    .btn-main{
      min-width: 120px;
      padding: 0;
      &.secondary{
        min-width: 120px;
        padding: 0;
      }
    }
  }
}
.notice{
  @include font(14px);
  color: #6D6D6D;
  margin-top: 30px;

}

.section_title_small{
  @include flex();
  flex-direction: row;
  align-content: center;
  margin-bottom: 20px;
  border-bottom: 1px solid $border;
  padding-bottom: 15px;
  h4{
    color: $base-text;
    @include font(16px);
    margin-bottom: 0;
  }
  p{
    margin-left: auto;
    margin-bottom: 0;
    color: $red;
    @include font(16px);
    padding-right: 15px;
  }
}

.quote_success_flow{
  .quote_success_title{
    @include flex();
    align-items: center;
    border-bottom: 1px solid $border;
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative;
    h3{
      color: #4B4B4B;
      margin-bottom: 0;
    }
    p{
      @include font(13px);
      @include line(22px);
      margin-bottom: 0;
    }
    .Status_dd{
      position: relative;
      ul{
        position: absolute;
        width: 112px;
        border-radius: 12px;
        background: #fff;
        @include box-shadow(0px 3px 6px rgba(0, 0, 0, 0.11));
        padding: 5px 15px;
        margin: 10px 0 0 0;
        right: 0;
        z-index: 9;
        li{
          cursor: pointer;
          border-bottom: 1px solid $border;
          padding: 10px 0px;
          &:last-child{
            border: none;            
          }
        }
      }
      .editBtns{
        // position: absolute;
        @include flex();
        justify-content: space-around;
        margin-top: 10px;
        width: 100%;
        left: 0;
        .btn-main{
          min-width: 95px;
          margin-left: 0;
        }
      }      
    }
    .btn-main{
      margin-left: auto;
      min-width: inherit;
      padding: 0;
      font-size: 12px;
    }
    .toggle_dot{
      position: absolute;
      right: 0;
      top: 15px;
      cursor: pointer;
    }
    
  }
  .quote_date_info{
    span{
      color: #BCBCBC;
      @include font(14px);
      font-weight: 600;
    }
    p{
      color: $base-text;
      @include font(14px);
      font-weight: 600;
      margin-bottom: 0px;
      strong{
        font-weight: 700;
      }
    }
    h3{
      color: $red;
      @include font(28px);
      font-weight: 700;
    }
  }
  .crm_table{
    .table{
      .show_added_list{
        padding: 0px 15px;
        .part_qty_quote{
          margin-right: 30px;
          b{
            color: #BCBCBC;
          }
        }
        &.h-40{
          height: 40px;
          margin-bottom: 10px;
        }
      }
      .form-group{
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 25px;
        padding-top: 25px;
        margin-bottom: 0;
      }
    }
  }

}

.request_filter{
  @include flex();
  border-bottom: 1px solid $primary;
  margin-bottom: 20px;
  padding-bottom: 20px;
  margin-top: 40px;
  .filter_text{
    @include font(16px);
    font-weight: 400;
    color: $border;
    cursor: pointer;
    &.active{
      color: $primary;
    }
  }
}

.Status_dd{
  position: relative;
  ul{
    position: absolute;
    width: 112px;
    border-radius: 12px;
    background: #fff;
    @include box-shadow(0px 3px 6px rgba(0, 0, 0, 0.11));
    padding: 5px 15px;
    margin: 10px 0 0 0;
    right: 0;
    z-index: 9;
    li{
      cursor: pointer;
      border-bottom: 1px solid $border;
      padding: 10px 0px;
      &:last-child{
        border: none;            
      }
    }
  }
  .editBtns{
    // position: absolute;
    @include flex();
    justify-content: space-around;
    margin-top: 10px;
    width: 100%;
    left: 0;
    .btn-main{
      min-width: 95px;
      margin-left: 0;
    }
  }      
}

.toggle_dot{
  cursor: pointer !important;
}