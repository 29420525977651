/*==========| Footer Section SCSS |==========*/
/*==========| Log Page SCSS |==========*/
.log_update_page{
	width: calc(100% - 580px) !important;
	@include screen(laptop){
		width: calc(100% - 480px) !important;
	}	
	.show_log_updates{
		margin-top: 50px;
	}

	.add_new_update{
		margin-top: 40px;
		display: none;
		.card{
			border-left: none;
			.card-header{
				h2{
					@include font(16px);
					font-weight: 500;
					img{
						margin-left: 15px;
					}
				}
			}			
		}
	}
}

.log_right_sidebar{
	background: $white;
	max-width: 420px;	
	flex: 0 0 420px;
	@include box-shadow(0px 3px 9px rgba(190, 190, 190, 0.42));
	// height: calc(100vh - 70px);
	padding: 30px;
	.log_edit{
		border-bottom: 1px solid $border;
		padding-bottom: 20px;
    	margin-top: 20px;
    	a{
    		color: $red;
    		display: block;
    		img{
    			margin-right: 10px;
    		}
    	}
	}
	ul{
		padding-left: 0;
		li{
			border-bottom: 1px solid $border;
			padding: 20px 0px;
			color: $base-text;
			font-weight: 500;
			@include font(14px);
			.product_series{
				h3{
					@include font(24px);
					@include line(40px);
					color: $red;
					margin-bottom: 0;
				}
			}
			&:last-child{
				border-bottom: none;
			}
			.log_not_found{
				background: #FCD8D8;
				border-radius: 4px;
				width: 100%;
				text-align: center;
				color: #FD0000;
				font-size: 16px;
				font-weight: 600;
				padding: 8px 5px;
				margin: 30px 0px;
			}
			.log_found{
				background: rgba(97, 185, 90, 0.10);
				border-radius: 4px;
				width: 100%;
				text-align: center;
				color: #61B95A;
				font-size: 16px;
				font-weight: 600;
				padding: 8px 5px;
				margin: 30px 0px;
			}
		}
	}
	@include screen(laptop){
		width: 380px;
	}
	.tensionmeter_edit_for{
		margin-top: 30px;
		.form-group{
			margin-bottom: 30px;
		}
		.react-datepicker-wrapper{
			display: block;
		}
	}
	
}

.opti-footer{
	height: 80px;
	background: #311313;
	@include flex();
	padding: 0px 45px;
	align-items: center;
	p{
		margin-bottom: 0;
		@include font(16px);
		font-weight: 600;
		color: white;
	}
	a{
		@include font(16px);
		font-weight: 600;
		color: white;
		margin-left: auto;
	}
}