.userModule{
    width: 100%;
    .card{        
        border: none;
        box-shadow: 0px 3px 9px rgba(190, 190, 190, 0.42);
        border-radius: 10px;
        .card-body{
            padding: 40px 20%;
            .logo{
                text-align: center;
                margin-bottom: 50px;
            }
            .form_link{
                color: #61B95A;
                margin: auto 0 auto auto;
            }
            h1{
                font-size: 36px;
                color: #5c5c5c;
                font-weight: 700;
            }
        }
    }
}
