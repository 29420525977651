.faq_page{
    .section_break{
        margin-bottom: 40px;
    }
    h3{
        color: $red;
        @include font(20px);
        margin-top: 0;
        margin-bottom: 5px;
    }
    h4{
        color: $red;
        @include font(14px);
        @include line(24px);
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid $red;
    }
    h5{
        color: $base-text;
        @include font(14px);
        @include line(24px);
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 2px;
    }
    p{
        color: $base-text;
        @include font(14px);
        @include line(24px);
        margin-bottom: 30px;
    }
    ul{
        padding-left: 25px;
        margin-top: 8px;
        list-style: disc;
        margin-bottom: 20px;
        p{
            margin-left: -25px;
            margin-bottom: 5px;
        }
        li{
            margin-bottom: 5px;
        }
    }
    .btn_groups{
        .btn-main{
            margin-right: 15px;
            height: 26px;
            min-width: 115px;
            @include font(12px);
            font-weight: 600;
            @include line(26px);
            &.green{
                border-color: $green;
                color: $green;
            }
            &.black{
                border-color: $base-text;
                color: $base-text;
            }
        }
    }
}