.part_page{
	.crm_table{
		.table{
			.head_row{
				padding: 0px 20px;
				.col{
					text-align: left;
				}
			}
			.show_added_list{
				padding: 0px 20px;
				height: 100px;
				.row{
					.col{
						text-align: left;
						font-weight: 600;
						h4{
							font-size: 16px;
						    margin-bottom: 4px;
						    font-weight: 700;
						    color: #3E3E3E;
						}
						.client_email{
							margin-bottom: 1px;
							font-size: 14px;
							color: #3E3E3E;
							font-weight: 600;
						}
						.address{
							font-size: 14px;
						}
					}
				}
			}
		}
	}	
}