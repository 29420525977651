/*==========| Sidenav Section SCSS |==========*/
.sidenav{
	background: $dark;
	max-width: 260px;	
	flex: 0 0 260px;
	// height: calc(100vh - 70px);
	ul{
		margin: 0;
		padding: 30px 0px 0px 0px;
		li{
			margin-bottom:5px;
			a{
				@include font(15px);
				font-weight: 500;
				color: $offwhite;
				display: block;
				padding: 10px 0px 10px 30px;
				background: transparent;
				border-left: 5px solid transparent;
				&:hover, &.active{
					color: $white;
					background: $dark-red;
					border-left: 5px solid $red;
					svg{
						g{
							opacity: 1;							
						}
						path{
							opacity: 1;
						}
					}
				}
				svg{
					margin-right: 10px;
				}
				.fas{
					margin-right: 10px;
				}
			}
			.sub_nav{
				padding-top: 0;
				padding-left: 28px;
				// background-color: $dark-red;
				// border-left: 5px solid $red;
				padding-bottom: 15px;
				li{
					margin-bottom: 0;
					a{
						padding-top: 8px;
						padding-bottom: 8px;
						@include font(14px);
						color: rgba(255, 255, 255, 0.5);
						&:hover, &.active{
							color: $white;
							background: transparent;
						}						
					}
				}
			}
			&.nested{
				// background: $dark-red;
				a{
					color: $white;
					border: none;
				}
			}
		}
		.gap{
			margin: 60px 0px;
		}
	}
}