.client_info{
	display: block;
	padding-right: 0 !important;
	.card{
		border-left: none !important;  
	}
	.form-group{
		margin-bottom: 30px;
		.form-control{
			border-radius: 60px;
		}
		textarea.form-control{
			border-radius: 4px;
			height: 120px;
		}
	}
}

.log_right_sidebar{
	h2{
		font-size: 18px;
		border-bottom: 1px solid #eee;
		margin-bottom: 30px;
	}
	.error{
		color: #CA3C3C;
		font-size: 12px;
	}
}
.service_request_page{
	.crm_table{
		.table{
			.head_row{
				padding: 0px 20px;
				.col{
					text-align: left;
				}
			}
			.show_added_list{
				padding: 0px 20px;
				height: 100px;
				.row{
					.col{
						text-align: left;
						font-weight: 600;
						h4{
							font-size: 16px;
						    margin-bottom: 4px;
						    font-weight: 700;
						    color: #3E3E3E;
						}
						.client_email{
							margin-bottom: 1px;
							font-size: 14px;
							color: #3E3E3E;
							font-weight: 600;
						}
						.address{
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	.react-datepicker-wrapper{
		display: block;
		input{
			border-radius: 60px;
			height: 40px;
			line-height: 40px;
			font-size: 14px;
			text-align: left;
			padding: 0.375rem 0.75rem;
			width: 100%;
			display: block;
			border: 1px solid #DEDEDE;
			&:focus{
				outline: none;
				box-shadow: none;
				border: 1px solid #1A0909;
			}
		}
	}
	.comment_section_wrapper{
		margin-top: 40px;
		.show_reply_msg{
			border-bottom: 1px solid #dedede;
			padding-bottom: 25px;
			margin-bottom: 25px;
			padding-left: 20px;
			p{
				font-size: 14px;
				margin-bottom: 0;
			}
			span{
				a{
					display: inline-block;
					color: #CA3C3C;
					font-size: 13px;
					font-weight: 600;
				}
			}
		}
		.comment_section{
			background: #3E3E3E;
			border-radius: 4px;
			margin-top: 20px;
			.form-group{
				label{
					color: #fff;
				}
				.upload_file{
					width: 130px;
					height: 30px;
					line-height: 30px;
					background: transparent;
					color: #BEBEBE;
					span{
						img{
							margin-left: 15px;
						}
					}
				}
			}
			.form_button{
				border-top: 1px solid rgba(190, 190, 190, 0.14);
				padding-top: 20px;
				margin-top: 20px;
			}
		}
	}
	
	
}